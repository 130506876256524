<template>
	<div class="news">
		<div class="news_title">
			<p class="news_title_cn">新闻动态</p>
			<p class="news_title_en">NEWS</p>
		</div>

		<div class="news_list">
			<div v-for="(item, index) in list" :key="index" class="news_item" @click="itemClick(item, index)">
				<p class="news_item_title">{{ item.title }}</p>
				<p class="news_item_time">{{ formatTime(item.create_time) }}</p>
			</div>
		</div>

		<van-pagination class="page" v-model="page" :total-items="total" :show-page-size="pageSize" force-ellipses @change="init"/>
	</div>
</template>

<script>
	export default {
		name: "index",
		data() {
			return {
				list: [],
				total: 1,
				page: 1,
				pageSize: 12
			}
		},
		created() {
			this.init();
		},
		methods: {
			async init() {
				this.$loading();
				let params = {
					module_id: 4,
					page: this.page,
					pageSize: this.pageSize
				}

				const res = await this.$api.contentList(params);
				this.$loading().clear();

				this.total = res.data.totalRow;
				this.list = res.data.list;
			},

			formatTime(time) {
				let mm = time.substr(4, 2);
				let dd = time.substr(6, 2);
				return mm + '/' + dd
			},

			itemClick(item, index) {
				this.$router.push('/news/' + item.content_id);
			}
		}
	}
</script>

<style lang="less" scoped>
	@import '~@/assets/fonts/fonts.css';

	.news {
		width: 100%;
		max-width: 100rem;
		margin: 0 auto;
		padding: 10rem 1rem;
		overflow: hidden;
	}

	.news_title {
		color: @greenColor;
		padding-bottom: 2.5rem;
		border-bottom: 1px solid @greenColor;
	}

	.news_title_cn {
		font-size: 5.4rem;
	}

	.news_title_en {
		font-size: 1.5rem;
		margin-top: 0.5rem;
	}

	.news_list {
		width: 100%;
	}

	.news_item {
		width: 100%;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #e3e3e3;
		font-size: 2.4rem;
		font-family: 'shangshushui';
		color: @blackColor;
		line-height: 7rem;
		cursor: pointer;
	}

	.news_item_title {
		width: 100%;
		.ellipsis;
	}

	.news_item_time {
		flex-shrink: 0;
		margin-left: 3rem;
	}

	.page {
		width: 60rem;
		margin: 3rem auto 0;
	}

	.page /deep/ .van-pagination__item {
		color: @greenDarkColor;
	}

	.page /deep/ .van-pagination__item--active {
		background-color: @greenDarkColor;
		color: #ffffff;
	}





	@media screen and (max-width: 800px) {
		.news {
			width: 32rem;
			padding: 3.1rem 1rem;
		}

		.news_title {
			padding-bottom: 0.8rem;
		}

		.news_title_cn {
			font-size: 1.9rem;
		}

		.news_title_en {
			font-size: 0.6rem;
			margin-top: 0.2rem;
		}

		.news_item {
			background-color: #def5d9;
			line-height: 2.5rem;
			font-size: 1.2rem;
			padding: 0 1rem;
			margin-top: 0.8rem;
			border-bottom: none;
		}

		.news_item_time {
			margin-left: 1.5rem;
		}

		.page {
			width: 100%;
			font-size: 1rem;
		}

		.page /deep/ .van-pagination__item {
			height: 3.4rem;
		}
	}
</style>